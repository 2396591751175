<template>
    <footer class="text-center">
        <div class="container">
            <a href="" class="pressdave"></a>

            <nav class="navMenu">
                <a href="https://discord.gg/Gj7JqN2mXZ">About</a>
                <a href="https://discord.gg/Gj7JqN2mXZ">♦ Diamonds</a>
                <a href="https://docs.pumpkitten.com/fantoon/fantoon-nft-launchpad">Docs</a>
                <a href="https://discord.gg/Gj7JqN2mXZ">Projects</a>
                <a href="https://twitter.com/pumpkittens">Help</a>
            </nav>

            <div class="socialMedia">
                <a href="" class="facebook"></a>
                <a href="" class="twitter"></a>
                <a href="" class="discord"></a>
                <a href="" class="github"></a>
            </div>

            <div class="copyright text-center">
                &copy; 2022 Fantoon. All rights reserved
            </div>

        </div>
    </footer>
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>